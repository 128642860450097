import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DialogBoxComponent from '../../components/dialog-box/dialog-box.container';
import ErrorDisplayControl from '../../components/error-display/error-display.container';
import LoadingDisplay from '../../components/loading-spinner/loading-display';
import EmptyList from '../../components/empty-list/empty-list';
import MessageDisplay from '../../components/message-display/message-display';
import ForecourtControllerItem from './forecourt-controller-item';
import { AuthorisationModel } from '../../models/baseModels/authorisationModel';
import { HeaderStateModel } from '../../models/baseModels/headerStateModel';
import { PageSettingStateModel } from '../../models/baseModels/pageSettingStateModel';
import { ForecourtControllerRecordModel } from '../../models/forecourtControllerModel';
import { ModuleName } from '../../constants/module-constants';
import { Messages } from '../../constants/messages';
import { LoadingStatus } from '../../constants/loading-constants';
import './styles/forecourt-controllers.scss';

interface ForecourtControllersProps {
  forecourtControllerStatus: string;
  forecourtControllerContent: ForecourtControllerRecordModel[];
  authStatus: string;
  userAccess: (moduleName: string) => AuthorisationModel;
  loadForecourtControllers: () => void;
  deleteForecourtController: (data: string) => void;
  setHeaderConfiguration: (data: HeaderStateModel) => void;
  openDialogBox: () => void;
  closeDialogBox: () => void;
  setPageConfiguration: (data: PageSettingStateModel) => void;
  setIsPageDirty: (data: boolean) => void;
}

const ForecourtControllers: React.FC<ForecourtControllersProps> = (props: ForecourtControllersProps) => {
  const {
    forecourtControllerStatus,
    forecourtControllerContent,
    authStatus,
    userAccess,
    loadForecourtControllers,
    deleteForecourtController,
    setHeaderConfiguration,
    openDialogBox,
    closeDialogBox,
    setPageConfiguration,
    setIsPageDirty,
  } = props;

  const navigate = useNavigate();
  const { orgId, siteId } = useParams();
  const isLoadForecourtControllersRequested = useRef(false);

  /** CHECK AUTH STATUS */
  const [authSuccess, setHasAuthSuccess] = useState(false);
  const [authError, setHasAuthError] = useState(false);
  const [hasNoSystemAccess, setHasNoSystemAccess] = useState(false);
  const [authCheckCompleted, setHasAuthCheckCompleted] = useState(false);

  useMemo(() => {
    setHasAuthSuccess(authStatus === LoadingStatus.SUCCESS);
    setHasAuthError(authStatus === LoadingStatus.ERROR);
    setHasNoSystemAccess(authStatus === LoadingStatus.NOACCESS);
    setHasAuthCheckCompleted(authSuccess || authError || hasNoSystemAccess);
  }, [authError, authStatus, authSuccess, hasNoSystemAccess]);

  /** CHECK ACCESS STATUS */
  const [hasReadAccess, setHasReadAccess] = useState(false);
  const [hasCreateAccess, setHasCreateAccess] = useState(false);
  const [hasUpdateAccess, setHasUpdateAccess] = useState(false);
  const [hasDeleteAccess, setHasDeleteAccess] = useState(false);

  useMemo(() => {
    setHasReadAccess(userAccess(ModuleName.FORECOURT_CONTROLLER).hasReadAccess);
    setHasCreateAccess(userAccess(ModuleName.FORECOURT_CONTROLLER).hasCreateAccess);
    setHasUpdateAccess(userAccess(ModuleName.FORECOURT_CONTROLLER).hasUpdateAccess);
    setHasDeleteAccess(userAccess(ModuleName.FORECOURT_CONTROLLER).hasDeleteAccess);
  }, [userAccess]);

  /** CHECK LOADING STATUS */
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  useMemo(() => {
    setLoading(forecourtControllerStatus === LoadingStatus.LOADING && hasReadAccess);
    setSuccess(forecourtControllerStatus === LoadingStatus.SUCCESS && hasReadAccess);
    setError(forecourtControllerStatus === LoadingStatus.ERROR || authError);
  }, [authError, hasReadAccess, forecourtControllerStatus]);

  const emptyListMessage = 'No forecourt controllers found';
  const [deleteContext, setDeleteContent] = useState('');
  const [selectedId, setSelectedId] = useState('');

  useEffect(() => {
    if (!isLoadForecourtControllersRequested.current && authSuccess && hasReadAccess) {
      loadForecourtControllers();
    }
    isLoadForecourtControllersRequested.current = true;
  }, [authSuccess, hasReadAccess, loadForecourtControllers]);

  useEffect(() => {
    setHeaderConfiguration({
      title: 'Forecourt Controllers',
      showCreateButton: authSuccess && !loading ? hasCreateAccess : false,
      showSiteHeader: true,
      showInfoButton: false,
      showAccountOption: true,
      showOrganisation: false,
      error: error,
      pageURL: 'forecourt-controllers',
    } as HeaderStateModel);
  }, [setHeaderConfiguration, authSuccess, hasCreateAccess, error, loading]);

  useEffect(() => {
    setPageConfiguration({
      showFooter: true,
    } as PageSettingStateModel);
  }, [setPageConfiguration]);

  useEffect(() => {
    setIsPageDirty(false);
  }, [setIsPageDirty]);

  /** DIALOG BUTTONS */
  const confirmDialog = () => {
    deleteForecourtController(selectedId);
  };

  const cancelDialog = () => {
    closeDialogBox();
  };

  /** ACTION MENU (Edit, Delete) */
  const onDeleteHandler = useCallback(
    (forecourtControllerId: string, name: number) => {
      setSelectedId(forecourtControllerId);
      setDeleteContent(`Are you sure you want to delete ${name}?`);
      openDialogBox();
    },
    [openDialogBox]
  );

  const onEditHandler = (forecourtControllerId: string) => {
    navigate(`/organisations/${orgId}/sites/${siteId}/forecourt-controllers/${forecourtControllerId}/details/edit`);
  };

  const onClick = (forecourtControllerId: string) => {
    navigate(`/organisations/${orgId}/sites/${siteId}/forecourt-controllers/${forecourtControllerId}/details`);
  };

  return (
    <>
      {
        <DialogBoxComponent
          context={deleteContext}
          closeTextButton='No'
          confirmTextButton='Yes'
          confirmDialog={confirmDialog}
          onClose={cancelDialog}
          header='Delete Forecourt Controller'
        />
      }

      {(!authCheckCompleted || loading) && <LoadingDisplay />}
      {(hasNoSystemAccess || (authSuccess && !hasReadAccess)) && (
        <MessageDisplay
          messageTitle={Messages.NO_ACCESS_MESSAGE}
          messageContent={Messages.CONTACT_ADMIN}
        ></MessageDisplay>
      )}

      {error && <ErrorDisplayControl />}
      {success && (
        <div className='forecourt-controller-list'>
          {forecourtControllerContent &&
            forecourtControllerContent.map((item, i) => {
              return (
                <ForecourtControllerItem
                  id={item.id}
                  key={i}
                  forecourtControllerItem={item}
                  onDelete={onDeleteHandler}
                  onEdit={onEditHandler}
                  onClick={onClick}
                  hasDeleteAccess={hasDeleteAccess}
                  hasUpdateAccess={hasUpdateAccess}
                />
              );
            })}
          {<>{forecourtControllerContent?.length === 0 && <EmptyList message={emptyListMessage}></EmptyList>}</>}
        </div>
      )}
    </>
  );
};

export default ForecourtControllers;
