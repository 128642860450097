import { takeLatest, put, call, select, all, delay } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  setPriceSignStatus,
  setPriceSignContent,
  setPriceSignError,
  setPriceSignItem,
  setPriceSignDetailStatus,
  setPriceSignDetailError,
} from './reducers';
import { reLoadTerminalData } from '../terminal/sagas';
import { selectTerminalData } from '../terminal/selectors';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { PriceSignModel, PriceSignRecordModel } from '../../models/priceSignModel';
import { TerminalOperationActionPostModel } from '../../models/terminalOperationModel';
import { TerminalModel, TerminalRecordModel } from '../../models/terminalModel';
import { getApiErrorMessage, getGenericErrorMessage } from '../../utilities/errorhandler';
import { Messages } from '../../constants/messages';
import { LoadingStatus } from './../../constants/loading-constants';
import { selectOrganisationId } from '../auth/selectors';
import { setSnackBarError, setSnackBarSuccess } from '../snackbar/reducers';
import { setGenericErrorData } from '../generic-error/reducers';
import { setDialogBoxActionStatus, closeDialogBox } from '../dialog-box/reducers';
import { showBackdrop, hideBackdrop, setBackDropActionStatus, setBackDropError } from '../backdrop/reducers';
import { setIsPageDirty } from '../page-configuration/reducers';
import { PriceSignEntity, PriceSignListStatusResponse } from '../../entities/priceSign';
import { selectSelectedSiteId } from '../sites/selectors';
import * as actions from './actions';
import * as services from './services';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import * as dateTimeHelper from '../../utilities/datetime-helper';
import * as terminalActions from '../terminal/actions';
import * as terminalOperationServices from '../terminal-operations/services';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_PRICESIGNS, loadPriceSigns);
  yield takeLatest(actions.LOAD_PRICESIGNINFO, loadPriceSignInfo);
  yield takeLatest(actions.CREATE_PRICESIGN, createPriceSign);
  yield takeLatest(actions.EDIT_PRICESIGN, editPriceSign);
  yield takeLatest(actions.DELETE_PRICESIGN, deletePriceSign);
}

export function* loadPriceSigns() {
  const organisationId: string = yield select(selectOrganisationId);
  const siteId: string = yield select(selectSelectedSiteId);
  try {
    yield put(setPriceSignStatus(LoadingStatus.LOADING));
    let price_sign_response: PriceSignListStatusResponse = yield call(
      services.getAllPriceSignStatuses,
      organisationId,
      siteId
    );
    let priceSignData: PriceSignRecordModel[] = yield call(MapPriceSignsEntityToModel, price_sign_response);
    yield put(setPriceSignContent(priceSignData));
    yield put(setPriceSignStatus(LoadingStatus.SUCCESS));

    if (priceSignData && priceSignData?.length > 0) {
      // get terminal list first
      let terminalData: TerminalRecordModel[] = yield select(selectTerminalData);
      if (!terminalData || terminalData?.length <= 0) {
        yield call(reLoadTerminalData, {
          payload: {
            siteId: siteId,
            organisationId: organisationId,
          } as TerminalModel,
          type: terminalActions.RELOAD_TERMINALS,
        });
        terminalData = yield select(selectTerminalData);
      }

      // ask each terminal upload the latest price sign status
      const responses: any[] = yield all(
        terminalData.map((record) => {
          return call(function* () {
            try {
              yield call(terminalOperationServices.postOperationAction, record.id, {
                organisationId: organisationId,
                type: 'priceSignsStatusUpload',
              } as TerminalOperationActionPostModel);
              return { data: 200 };
            } catch (error) {
              return { data: 'error' };
            }
          });
        })
      );

      // if success receive data from operation api, retrieve the updated price sign data twice with 5 sec wait
      if (responses.some((result) => result.data === 200)) {
        yield delay(5000);
        yield reLoadPriceSignData(siteId, organisationId);
        yield delay(5000);
        yield reLoadPriceSignData(siteId, organisationId);
      }
    }
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setPriceSignError());
    yield put(setPriceSignStatus(LoadingStatus.ERROR));
  }
}

function* reLoadPriceSignData(siteId: string, organisationId: string): Generator<any, void, any> {
  try {
    let price_sign_response: PriceSignListStatusResponse = yield call(
      services.getAllPriceSignStatuses,
      organisationId,
      siteId
    );
    let priceSignData: PriceSignRecordModel[] = yield call(MapPriceSignsEntityToModel, price_sign_response);
    yield put(setPriceSignContent(priceSignData));
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setPriceSignError());
  }
}

export function* loadPriceSignInfo(action: PayloadAction<string>) {
  const organisationId: string = yield select(selectOrganisationId);
  try {
    yield put(setPriceSignDetailStatus(LoadingStatus.LOADING));
    let price_sign_response: PriceSignEntity = yield call(services.getPriceSignById, action.payload, organisationId);
    let priceSignData: PriceSignModel = yield call(MapPriceSignEntityToModel, price_sign_response);
    yield put(setPriceSignItem(priceSignData));
    yield put(setPriceSignDetailStatus(LoadingStatus.SUCCESS));
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setPriceSignDetailError());
    yield put(setPriceSignDetailStatus(LoadingStatus.ERROR));
  }
}

export function* createPriceSign(action: PayloadAction<PriceSignModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    let priceSignEntity = MapPriceSignModelToEntity(action.payload);
    yield call(services.createPriceSign, priceSignEntity);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.PRICESIGN_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    return;
  }
}

export function* editPriceSign(action: PayloadAction<PriceSignModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    let priceSignEntity = MapPriceSignModelToEntity(action.payload);
    yield call(services.editPriceSign, priceSignEntity, action.payload.id);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.PRICESIGN_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
  }
}

export function* deletePriceSign(action: PayloadAction<string>) {
  try {
    yield put(setDialogBoxActionStatus(LoadingStatus.SUBMITTED));
    const organisationId: string = yield select(selectOrganisationId);
    yield call(services.deletePriceSign, action.payload, organisationId);
    yield put(closeDialogBox());
    yield put(setSnackBarSuccess(Messages.PRICESIGN_DELETE_SUCESS));
    yield loadPriceSigns();
  } catch (error) {
    yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

const MapPriceSignsEntityToModel = (response: PriceSignListStatusResponse) => {
  if (response && response.items.length > 0) {
    const result: PriceSignRecordModel[] = response.items.map((item, i) => {
      return {
        id: item.id,
        organisationId: item.organisationId,
        siteId: item.siteId,
        number: item.number,
        status: item.status,
        lastUpdatedDateTimeUtc: dateTimeHelper.getDayCounter(item.dateTimeUtc),
        products: item?.products,
        messages: item?.messages,
      };
    });
    return result;
  }
  return [] as PriceSignRecordModel[];
};

const MapPriceSignEntityToModel = (response: PriceSignEntity) => {
  if (response.id) {
    return {
      id: response.id,
      organisationId: response.organisationId,
      siteId: response.siteId,
      number: response.number,
      protocolType: response.protocol.type,
      protocolBaudRate: response.protocol.baudRate.toString(),
      products: response.products,
    } as PriceSignModel;
  } else throw new Error('Not a valid Price Sign API response');
};

const MapPriceSignModelToEntity = (model: PriceSignModel) => {
  if (model) {
    let priceSignEntity = {
      organisationId: model.organisationId,
      siteId: model.siteId,
      number: fieldMappingHelper.sanitizeNumericValue(model.number),
      protocol: {
        type: model.protocolType,
        baudRate: fieldMappingHelper.convertStringToNumber(model.protocolBaudRate),
      },
      products: model.products,
    } as PriceSignEntity;
    return priceSignEntity;
  }
  return {} as PriceSignEntity;
};
