import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, put, call, select, delay, all } from 'redux-saga/effects';
import * as fieldHelper from '../../utilities/field-helper';
import * as datetimeHelper from '../../utilities/datetime-helper';
import * as fieldMappingHelper from '../../utilities/fieldMapping-helper';
import * as actions from './actions';
import * as terminalActions from '../terminal/actions';
import * as services from './services';
import * as terminalOperationServices from '../terminal-operations/services';
import * as tankService from '../tanks/services';
import * as productService from '../product/services';
import KeyValuePair from '../../models/baseModels/keyValuePairModel';
import {
  setPumpStatus,
  setPumpContent,
  setPumpError,
  setPumpInfo,
  setPumpNumberList,
  setPumpDetailStatus,
  setPumpDetailError,
} from './reducers';
import { PumpInfoEntity, PumpModalHose, PumpResponse } from '../../entities/pump';
import { TankResponse } from '../../entities/tank';
import { ProductsInfoEntity, ProductsResponse } from '../../entities/product';
import { PumpRecordModel, PumpInfoModel, HoseDetail, PumpModel } from '../../models/pumpModel';
import { GenericErrorModel } from '../../models/baseModels/genericErrorModel';
import { TerminalOperationActionPostModel } from '../../models/terminalOperationModel';
import { getApiErrorMessage, getGenericErrorMessage } from '../../utilities/errorhandler';
import { setSnackBarError, setSnackBarSuccess } from '../snackbar/reducers';
import { Messages } from '../../constants/messages';
import { LoadingStatus } from './../../constants/loading-constants';
import { baudRate, communicationProtocol, displayType, pumpSettingsFlags } from '../../constants/dropdown-constants';
import { setGenericErrorData } from '../generic-error/reducers';
import { setTankNameList } from '../tanks/reducers';
import { setDialogBoxActionStatus, closeDialogBox } from '../dialog-box/reducers';
import { selectOrganisationId } from '../auth/selectors';
import { clearAllFieldValidation } from '../fieldValidation/reducers';
import { hideBackdrop, setBackDropActionStatus, setBackDropError, showBackdrop } from '../backdrop/reducers';
import { setIsPageDirty } from '../page-configuration/reducers';
import { reLoadTerminalData } from '../terminal/sagas';
import { TerminalModel, TerminalRecordModel } from '../../models/terminalModel';
import { selectTerminalData } from '../terminal/selectors';
import { selectSelectedSiteId } from '../sites/selectors';

export function* rootSaga() {
  yield takeLatest(actions.LOAD_PUMPS, loadPumps);
  yield takeLatest(actions.LOAD_PUMPINFO, loadPumpInfo);
  yield takeLatest(actions.EDIT_PUMP, editPumpItem);
  yield takeLatest(actions.CREATE_PUMP, createPumpItem);
  yield takeLatest(actions.DELETE_PUMPS, deletePumpsItem);
  yield takeLatest(actions.LOAD_PUMPNUMBER_LIST, loadPumpNumberList);
}

export function* loadPumps(action: PayloadAction<PumpModel>) {
  try {
    yield put(setPumpStatus(LoadingStatus.LOADING));

    let response: PumpResponse = yield call(services.getAllPumpData, action.payload);

    let pumpData: PumpRecordModel[] = yield call(MapPumpEntityToModel, response);
    yield put(setPumpContent(pumpData));
    yield put(setPumpStatus(LoadingStatus.SUCCESS));

    if (pumpData && pumpData.length > 0) {
      // get terminal list first
      let terminalData: TerminalRecordModel[] = yield select(selectTerminalData);
      if (!terminalData || terminalData?.length <= 0) {
        yield call(reLoadTerminalData, {
          payload: {
            siteId: action?.payload?.siteId,
            organisationId: action?.payload?.organisationId,
          } as TerminalModel,
          type: terminalActions.RELOAD_TERMINALS,
        });
        terminalData = yield select(selectTerminalData);
      }

      // ask each terminal upload the latest pump status
      const responses: any[] = yield all(
        terminalData.map((record) => {
          return call(function* () {
            try {
              yield call(terminalOperationServices.postOperationAction, record.id, {
                organisationId: action.payload.organisationId,
                type: 'pumpsStatusUpload',
              } as TerminalOperationActionPostModel);
              return { data: 200 };
            } catch (error) {
              return { data: 'error' };
            }
          });
        })
      );

      // if success receive data from operation api, retrieve the updated pump data twice with 5 sec wait
      if (responses.some((result) => result.data === 200)) {
        yield delay(5000);
        yield reLoadPumpData(action);
        yield delay(5000);
        yield reLoadPumpData(action);
      }
    }
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }

    yield put(setPumpError());
  }
}

function* reLoadPumpData(action: PayloadAction<PumpModel>): Generator<any, void, any> {
  try {
    let response: PumpResponse = yield call(services.getAllPumpData, action.payload);
    let pumpData: PumpRecordModel[] = yield call(MapPumpEntityToModel, response);
    yield put(setPumpContent(pumpData));
  } catch (error: any) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(setPumpError());
  }
}

export function* loadPumpInfo(action: PayloadAction<PumpModel>) {
  try {
    if (!!action.payload) {
      yield put(setPumpDetailStatus(LoadingStatus.LOADING));

      let tankResponse: TankResponse = yield call(
        tankService.getTankList,
        action.payload.siteId,
        action.payload.organisationId
      );

      let productResponse: ProductsResponse = yield call(productService.getProductsData, action.payload.organisationId);

      let tankNameList: KeyValuePair[] = yield call(mapTankEntityToKeyValuePair, tankResponse, productResponse);

      let response: PumpInfoEntity = yield call(services.getPumpInfo, action.payload);

      let pumpInfo: PumpInfoModel = yield call(MapPumpInfoEntityToModel, response, tankNameList);

      yield put(setTankNameList(tankNameList));
      yield put(setPumpInfo(pumpInfo));

      yield put(setPumpDetailStatus(LoadingStatus.SUCCESS));
    }
  } catch (error) {
    if (!!error) {
      let genericErrorData: GenericErrorModel = getGenericErrorMessage(error);
      yield put(setGenericErrorData(genericErrorData));
    }
    yield put(clearAllFieldValidation());
    yield put(setPumpDetailError());
  }
}

export function* createPumpItem(action: PayloadAction<PumpInfoModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    let pumpEntity = MapPumpInfoModelToEntity(action.payload);
    yield call(services.createPumpData, pumpEntity);
    let pumpModel = {
      organisationId: action.payload.organisationId,
      siteId: action.payload.siteId,
    } as PumpModel;
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.PUMP_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* editPumpItem(action: PayloadAction<PumpInfoModel>) {
  try {
    yield put(showBackdrop());
    yield put(setBackDropActionStatus(LoadingStatus.SUBMITTED));
    let pumpEntity = MapPumpInfoModelToEntity(action.payload);
    pumpEntity.organisationId = yield select(selectOrganisationId);
    yield call(services.editPumpData, pumpEntity, action.payload.id);
    yield put(setIsPageDirty(false));
    yield put(setBackDropActionStatus(LoadingStatus.SUCCESS));
    yield delay(10);
    yield put(setSnackBarSuccess(Messages.PUMP_SAVE_SUCCESS));
    yield put(hideBackdrop());
  } catch (error) {
    yield put(setBackDropActionStatus(LoadingStatus.ERROR));
    yield put(setBackDropError(true));
    yield put(hideBackdrop());
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* deletePumpsItem(action: PayloadAction<PumpModel>) {
  try {
    yield put(setDialogBoxActionStatus(LoadingStatus.SUBMITTED));
    yield call(services.deletePumpData, action.payload);
    yield put(closeDialogBox());
    yield put(setSnackBarSuccess(Messages.PUMP_DELETE_SUCESS));
    yield call(loadPumps, action);
  } catch (error) {
    yield put(setDialogBoxActionStatus(LoadingStatus.ERROR));
    let errorMsg = getApiErrorMessage(error);
    yield put(setSnackBarError(errorMsg));
  }
}

export function* loadPumpNumberList(action: PayloadAction<string>) {
  try {
    const organisationId: string = yield select(selectOrganisationId);
    let pumps: PumpResponse = yield getPumpList(organisationId, action.payload);
    let pumpNumberList: KeyValuePair[] = yield call(mapPumpEntityToKeyValuePair, pumps);
    yield put(setPumpNumberList(pumpNumberList));
  } catch {
    yield put(setPumpError());
  }
}

export function* getPumpList(organisationId: string, siteId?: string) {
  try {
    let updatedSiteId = siteId as string;
    if (!siteId) updatedSiteId = yield select(selectSelectedSiteId);
    let pumpList: PumpResponse = yield call(services.getPumpList, updatedSiteId, organisationId);
    return pumpList;
  } catch (error) {
    yield put(setPumpError());
  }
}

const MapPumpEntityToModel = (response: PumpResponse) => {
  if (response && response?.items?.length > 0) {
    const result: PumpRecordModel[] = response.items.map((record, i) => {
      return {
        id: record.id,
        siteId: record.siteId,
        status: fieldHelper.getDefaultStringvalue(record.status),
        healthIndicator: fieldHelper.getPumpStatusIndicator(record.status),
        lastUpdatedDateTimeUtc: datetimeHelper.getDayCounter(record.dateTimeUtc),
        number: record.number,
        transactions: record?.transactions,
        messages: record?.messages,
      } as PumpRecordModel;
    });

    return result;
  }

  return [] as PumpRecordModel[];
};

const MapPumpInfoEntityToModel = (response: PumpInfoEntity, tankList: KeyValuePair[]) => {
  if (response) {
    return {
      id: response.id,
      organisationId: response.organisationId,
      siteId: response.siteId,
      number: fieldMappingHelper.validateNumericValue(response.number),
      channel: fieldMappingHelper.validateNumericValue(response.channel),
      communicationProtocol: fieldMappingHelper.validateEnumValue(
        response?.protocol.type?.toString(),
        communicationProtocol
      ),
      pumpAddress: fieldMappingHelper.validateNumericValue(response?.protocol?.address),
      enabled: fieldMappingHelper.validateBooleanValue(response.enabled),
      baudRate: response?.protocol?.baudRate
        ? fieldMappingHelper.validateEnumValue(response?.protocol?.baudRate?.toString(), baudRate)
        : 'default',
      displayType: fieldMappingHelper.validateEnumValue(response?.displayType?.toString(), displayType),
      flowTimeOut: fieldMappingHelper.validateNumericValue(response.noFlowTimeOut),
      pumpSettingsFlags: fieldMappingHelper.validateEnumValue(
        response?.pumpSettingsFlags?.toString(),
        pumpSettingsFlags
      ),
      authorisationAmountOffset: fieldMappingHelper.validateNumericValue(response.authorisationAmountOffset),
      hoses: response?.hoses?.map((item, i) => {
        return {
          tankId: tankList.find((it) => it.key === item.tankId)
            ? fieldMappingHelper.validateEnumValue(item.tankId, tankList)
            : '',
          number: item.number,
        } as HoseDetail;
      }),
      defaultOnlineAuthorisationAmount: fieldMappingHelper.validateNumericValue(
        response?.defaultAuthorisationAmount?.online
      ),
      defaultOfflineAuthorisationAmount: fieldMappingHelper.validateNumericValue(
        response?.defaultAuthorisationAmount?.offline
      ),
    } as PumpInfoModel;
  }

  return {} as PumpInfoModel;
};

const MapPumpInfoModelToEntity = (model: PumpInfoModel) => {
  if (model) {
    const hoses: PumpModalHose[] =
      model?.hoses && model?.hoses?.length > 0
        ? model?.hoses?.map((item, i) => {
            return {
              tankId: fieldMappingHelper.sanitizeStringValue(item.tankId),
              number: fieldMappingHelper.sanitizeNumericValue(item.number),
            } as PumpModalHose;
          })
        : [];

    let pumpInfoEntity = {
      organisationId: fieldMappingHelper.sanitizeStringValue(model.organisationId),
      siteId: fieldMappingHelper.sanitizeStringValue(model.siteId),
      number: fieldMappingHelper.sanitizeNumericValue(model.number),
      channel: fieldMappingHelper.sanitizeNumericValue(model.channel),
      protocol: {
        type: fieldMappingHelper.sanitizeStringValueFromDropdown(model.communicationProtocol),
        address: fieldMappingHelper.sanitizeNumericValue(model.pumpAddress),
        baudRate: model.baudRate === 'default' ? undefined : fieldMappingHelper.convertStringToNumber(model.baudRate),
      },
      enabled: model.enabled,
      displayType: fieldMappingHelper.sanitizeStringValueFromDropdown(model.displayType),
      noFlowTimeOut: fieldMappingHelper.sanitizeNumericValue(model.flowTimeOut),
      pumpSettingsFlags: fieldMappingHelper.convertStringToNumber(model.pumpSettingsFlags),
      hoses: hoses,
      authorisationAmountOffset: fieldMappingHelper.sanitizeNumericValue(model.authorisationAmountOffset),
      defaultAuthorisationAmount: {
        online: fieldMappingHelper.sanitizeNumericValue(model.defaultOnlineAuthorisationAmount),
        offline: fieldMappingHelper.sanitizeNumericValue(model.defaultOfflineAuthorisationAmount),
      },
    } as PumpInfoEntity;

    return pumpInfoEntity;
  }

  return {} as PumpInfoEntity;
};

const mapTankEntityToKeyValuePair = (response: TankResponse, products: ProductsResponse) => {
  if (response && response.items.length > 0) {
    const result: KeyValuePair[] = response.items.map((record, i) => {
      return {
        key: record.id,
        value: `${record.number} ${getProductName(products.items, record.productId)}`,
      };
    });
    return result;
  }
};

const getProductName = (productList: ProductsInfoEntity[], productId: string) => {
  if (!!productList) {
    let productName = productList.filter((i) => i.id === productId)[0]?.name;
    return productName ? `(${productName})` : '';
  }
  return '';
};

const mapPumpEntityToKeyValuePair = (response: PumpResponse) => {
  if (response && response.items.length > 0) {
    const result: KeyValuePair[] = response.items.map((record, i) => {
      return {
        key: record.id,
        value: record.number,
      };
    });
    return result;
  }
};
